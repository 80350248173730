<template>
  <div class="">
    <el-select
      v-model="contactactsModel"
      @change="contactChanged"
      autocomplete="new-password"
      :placeholder="placeholder ? placeholder : $t('COMMON.CONTACTS')"
      :filterable="filterable"
      :multiple="true"
      :disabled="disabled"
      remote
      :remote-method="getContacts"
      :loading="loading"
      collapse-tags
    >
      <el-option
        v-if="showAll"
        :value="null"
        :label="placeholder ? placeholder : $t('COMMON.CONTACTS')"
      >
      </el-option>
      <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
      </el-option>
      <el-option
        v-for="(contact, key) in contactList"
        :key="key"
        :value="contact.id"
        :label="`${contact.firstname} ${contact.lastname} - ${contact.email}`"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { Option, Select } from "element-ui";
import { difference } from "lodash";

export default {
  name: "contacts-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
    contacts: {
      type: Array,
      default: () => [],
      description: "Warehouse id",
    },
    filterIdsNotIn: {
      type: Array,
      default: () => [],
      description: "Warehouse not in list id",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    placeholder: {
      type: String,
      default: null,
      description: "Placeholder",
    },
  },

  data() {
    return {
      contactactsModel: [...this.contacts].map((item) => item.id),
      contactList: [],
      loading: false,
    };
  },

  setup() {},

  created() {
    this.getContacts(null, this.contact);
  },

  methods: {
    async getContacts(query = null, ids = null) {
      try {
        this.loading = true;
        let params = {
          sort: "-created_at",
          filter: {
            ...(query ? { search: query } : {}),
            ...(ids?.length ? { ids: ids.map((item) => item.id) } : {}),
          },
          page: {
            number: 1,
            size: 999,
          },
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.filterOrganization,
            },
          };
        }

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }

        if (this.filterIdsNotIn.length > 0) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              idsNotIn: this.filterIdsNotIn,
            },
          };
        }
        await this.$store.dispatch("contacts/list", params);
        const contactArr = await this.$store.getters["contacts/list"];
        this.contactList = {};
        contactArr.forEach((contact) => {
          this.contactList[contact.id] = contact;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    contactChanged(contacts) {
      this.$emit(
        "contactsChanged",
        [...contacts].map((item) => ({ type: "contacts", id: item }))
      );
    },
  },

  watch: {
    contacts(contacts) {
      if (contacts?.length) {
        const contactsData = contacts.map((item) => item.id);
        if (difference(contactsData, this.locationsModel).length !== 0) {
          this.contactactsModel = contactsData;
        }
      } else {
        this.contactactsModel = [];
      }
    },
    filterOrganization(filterOrganization) {
      this.getContacts();
    },
    filterIdsNotIn(filterIdsNotIn) {
      this.getContacts();
    },
  },
};
</script>
